/**  APIリクエストのラッパー*/
import requestWithJWT from "../lib/auth_request";
/**
 * サインアップ
 * @param body
 * @returns
 */
export const registerMe = (body) => {
  return requestWithJWT("post", "/v1/ad/ad_staff_register", body);
};

/**
 * ユーザー情報取得
 * @returns
 */
export const fetchMyAccountInfo = () => {
  return requestWithJWT("get", "/v1/ad/account");
};

/**
 * トップページ用の情報取得
 * @returns
 */
export const rfetchIndexPageData = () => {
  return requestWithJWT("get", "/v1/ad/index_page_data");
};

/**
 * ユーザーの支払い取得
 * @returns
 */
export const fetchMyPaymentInfo = () => {
  return requestWithJWT("get", "/v1/ad/payment");
};

/**
 * ユーザー情報編集
 * @param body
 * @returns
 */
export const editMyAccountInfo = (body) => {
  return requestWithJWT("put", "/v1/ad/account", body);
};

/**
 * ユーザーの所有する広告一覧取得
 * @returns
 */
export const fetchMyAds = () => {
  return requestWithJWT("get", "/v1/ad/manage/ads");
};

/**
 * 広告作成
 * @param body
 * @returns
 */
export const createMyAd = (body) => {
  return requestWithJWT("post", "/v1/ad/manage/ads", body);
};

/**
 * uuidの広告情報を取得
 * @param uuid
 * @returns
 */
export const getMyAdsDetail = (uuid: string) => {
  return requestWithJWT("get", `/v1/ad/manage/ads/${uuid}`);
};
/**
 * uuidの広告情報を編集
 * @param uuid
 * @param data
 * @returns
 */
export const editMyAdsDetail = (uuid: string, data) => {
  return requestWithJWT("put", `/v1/ad/manage/ads/${uuid}`, data);
};

/**
 * uuidの広告を削除
 * @param uuid
 * @returns
 */
export const deleteMyAd = (uuid: string) => {
  return requestWithJWT("delete", `/v1/ad/manage/ads/${uuid}`);
};

/**
 * stripeにカードを登録するのに必要なトークンを取得
 * @returns
 */
export const createSetupIntent = () => {
  return requestWithJWT("post", "/v1/ad/stripe/setup_intents");
};

/**
 * stripeに登録されているcard 情報を取得
 * @returns
 */
export const getMyCards = () => {
  return requestWithJWT("get", "/v1/ad/stripe/card");
};
/**
 * stripeに登録されているcard 情報を取得
 * @returns
 */
export const clearCacheOfMyCards = () => {
  return requestWithJWT("get", "/v1/ad/stripe/card/clear_cache");
};
/**
 *
 * @param uuid
 * @returns
 */
export const deleteMyCard = (uuid: string) => {
  return requestWithJWT("delete", `/v1/ad/stripe/card/${uuid}`);
};
/**
 *
 * @param data
 * @returns
 */
export const createDeposit = (data) => {
  return requestWithJWT("post", "/v1/ad/stripe/create_deposit", data);
};
/**
 * ユーザーアイコンを登録
 * @param data
 * @returns
 */
export const uploadIcon = (data) => {
  return requestWithJWT("post", "/v1/ad/icon_upload", data, true);
};
/**
 * 広告の画像を登録
 * @param data
 * @param ad_uuid
 * @returns
 */
export const uploadAdImage = (data, ad_uuid) => {
  return requestWithJWT("post", `/v1/ad/image_upload/${ad_uuid}`, data, true);
};
