import Image from "next/image";
import Link from "next/link";
import { Dialog, Input, Button, IconButton } from "@mui/material";
import { Twitter, Close } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAppState } from "./AppProvider";
import { useLocale } from "@/components/lang/UseLocale";

export default function Test() {
  const { t } = useLocale();

  const notebox_form_url = `${process.env.BACKEND_API}/v1/notebox/form`; //notebox用のお問合せ

  const [feedback, setFeedback] = useState<any>(false);
  const [value, setValue] = useState<any>("");
  const [sending, setSending] = useState<any>(false);
  const [sent, setSent] = useState<any>(false);
  const [email, setEmail] = useState<any>("");
  const handleChange = (e: any) => {
    e.preventDefault();
    setValue(e.target.value);
  };
  //お問合せを送信する関数
  const sendHandler = () => {
    setSending(true);
    const json = { message: value, email: email || "miss@example.com" };
    axios
      .post(notebox_form_url, json)
      .then((res) => {
        console.log(res);
        setSending(false);
        setValue("");
        setSent(true);
      })
      .catch((error) => {
        alert("Error");
        setSending(false);
        setSent(false);
      });
  };
  return (
    <>
      <footer className="bg-white dark:bg-gray-800 text-gray-900 dark:text-white text-center border-solid border-t border-blue-50 dark:border-gray-700 text-sm">
        <div>
          <div className="py-3" />

          <div className="flex justify-center py-3">
            <span>
              <Link scroll={false} href="/">
                <a>
                  <Image
                    src="/ads.svg"
                    alt="Notebox Logo"
                    width={72}
                    height={72}
                  />
                </a>
              </Link>
            </span>
          </div>

          <div className="text-center">
            <div className="py-2">
              <Link scroll={false} href="/help">
                <a>{t.HOWTOUSE}</a>
              </Link>
            </div>
            <div className="py-2">
              <a href="https://jp.magicode.io/ads/terms" target="_blank">
                {t.TERMS}
              </a>
            </div>

            <div className="py-2">
              <a href="https://jp.magicode.io/ads/privacy" target="_blank">
                {t.PRIVACYPOLICIES}
              </a>
            </div>
          </div>

          <div
            onClick={() => {
              setFeedback(!feedback);
            }}
            className="py-2"
          >
            <a>{t.CONTACT}</a>
          </div>

          <div className="flex justify-center py-2">
            <span className="px-2 no-change-link">
              <a href="https://twitter.com/Magicode_io" target="_blank">
                <Twitter
                  style={{ width: "30px", height: "30px", color: "#626262" }}
                />
              </a>
            </span>

            <span className="px-2">
              <a
                href="https://join.slack.com/t/magicodeio/shared_invite/zt-rvx96n00-tH4rGBrHs3xbv1xf4A5Y~Q"
                target="_blank"
              >
                <Image src="/icons/slack.svg" width={30} height={30} />
              </a>
            </span>
          </div>

          <div className="py-3"></div>
        </div>
      </footer>

      <Dialog
        onClose={() => {
          setFeedback(false);
          setSent(false);
        }}
        open={feedback}
        className="text-center"
      >
        <div style={{ padding: "24px", width: "300px", position: "relative" }}>
          {sent ? (
            <>
              <h4>
                <b>Thank you for your feedback!</b>
              </h4>
            </>
          ) : (
            <>
              <div className="py-3">
                <h4>
                  <b>{t.CONTACT}</b>
                </h4>
              </div>

              <div className="py-3">
                <Input
                  required
                  id="commentinput"
                  placeholder={t.YOUROPINION}
                  multiline
                  type="text"
                  inputProps={{ maxLength: 254 }}
                  value={value}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  className="text-black dark:text-white"
                />

                <div className="py-2 text-center">
                  <Input
                    required
                    id="emailinput"
                    placeholder="Email adress"
                    autoComplete="email"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    style={{ width: "100%" }}
                    className="text-black dark:text-white"
                  />
                </div>

                <div className="py-1">
                  <Button
                    onClick={sendHandler}
                    disabled={sending || value.trim() === ""}
                    variant="contained"
                    color="secondary"
                  >
                    <b style={{ color: "white" }}>{t.SUBMIT}</b>
                  </Button>
                </div>
              </div>
            </>
          )}

          <div style={{ position: "absolute", top: "5px", right: "5px" }}>
            <IconButton
              onClick={() => {
                setFeedback(false);
              }}
              color="inherit"
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </Dialog>
    </>
  );
}
