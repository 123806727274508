const msg = "Magicodeへようこそ!!";

export default {
  SIDEADS: "記事の横に広告を表示する",
  ARTICLE: "記事",
  BOOK: "本",
  CF1: "コード実行機能",
  CF2: "ブラウザでコードを動かそう",
  CF3: "その場でコードを編集して、環境構築なしで実行し、出力を確認・保存することができます。",
  CF4: "現在は13の言語に対応しています。",
  ED1: "便利なエディター",
  ED2: "快適な方法で知見を共有しよう",
  // ED3: "Magicodeでは、記事の公開ページと同じレイアウトで、マークダウンセル・コードセル・広告セルを積み重ねていくスタイルで執筆することができます。",
  ED3: "Magicodeでは、記事の公開ページと同じレイアウトで、マークダウンセル・コードセルを積み重ねていくスタイルで執筆することができます。",
  ED4: "ローカルやGithub上のマークダウン・Jupyter notebookファイルを直接投稿することもできます。",
  SERVICEDESCRIPTION:
    "新しいエンジニア向け投稿サイトのMagicodeに広告を掲載して効率的に訴求をしましょう。",
  TOPPAGEMSG:
    "Magicodeはエンジニア達が自らの知見を最大限に活用し、育むことができる情報共有コミュニティです。\nその場でコードを動かしながら執筆・閲覧したり、投稿者は簡単に収益化することができます。",
  INTEGRATEGITHUB: "Githubと連携する",
  DISSOLVEINTEGRATION: "連携を解消する",
  LAUNCHING: "接続中です...📶",
  UNAVAILABLESLUG: "使えない文字列です",
  MSG: msg,
  LOGLOGIN: "ログイン状態",
  DISPLAYNAME: "表示名",
  ACCOUNTID: "アカウントID（半角英数字・後で変更不可）",
  PROFILE: "プロフィール",
  DOMAINALREADYEXISTS: "このアカウントIDはすでに使われているようです。",
  NEXT: "次へ",
  CANCEL: "キャンセル",
  FOLLOWTAGS: "タグをフォローしましょう",
  RECOMMENDCONTENTS:
    "フォローしたタグに基づいて、おすすめのコンテンツの紹介や通知を行います。",
  REGISTER: "登録する",
  BACK: "戻る",
  LOGOUT: "ログアウト",
  ACCOUNT: "アカウント",
  SETTINGS: "設定",
  NOTIFICATION: "通知",
  COMMENT: "コメント",
  LIKE: "いいね",
  SUPERCOMMENT: "スーパーコメント",
  NONOTIFICATIONS: "通知はありません。",
  YOUR: "があなたの",
  LIKED_JA: "にいいねをしました。",
  COMMENTED_JA: "にコメントしました。",
  REPLIED_JA: "に返信しました。",
  REPLIED_EN: "",
  SUPERCOMMENTED_JA: "にスーパーコメントをしました。",
  BOUGHT_JA: "を購入しました。",
  LIKED_EN: "",
  COMMENTED_EN: "",
  SUPERCOMMENTED_EN: "",
  BOUGHT_EN: "",
  SELECT: "選択する",
  CONFIRM: "決定する",
  EDIT: "編集",
  DELETE: "削除",
  PARMANENTLYDELETE: "本当に削除しますか？",
  SAVE: "保存",

  POST: "送信",
  COPIED: "クリップボードにコピーしました",
  LOGINTOCOMMENT: "コメントにはログインが必要です。",
  FAILEDTOPOST: "何らかの理由で送信できませんでした。",
  AUTHENTICATIONFAILED: "認証できませんでした。",
  WRITECOMMENT: "コメントを入力",
  POSTSUPERCOMMENT: "スーパーコメントを送る",
  LINKCOPIED: "リンクをコピーしました",
  INPUTKEYWORDS: "キーワードを入力...",
  ERROR: "エラー!",
  HOUTOENJOY: "Magicodeの使い方",
  AFTERLOGGEDIN: "ログインすると全ての機能が使えるようになります。",
  WRITENEW: "記事の作成",
  LIKESANDCOMMENTS: "いいね・コメント",
  PAIDCONTENTS: "有料コンテンツの販売や購入・スーパーコメント",
  PLEASELOGIN_EN: "",
  PLEASELOGIN_JA: "に同意した上で、ログインしてください。",
  TERMS: "利用規約",
  AND: "および",
  OR: "または",
  PRIVACYPOLICIES: "プライバシーポリシー",
  MONTHAGO: "か月前",
  DAYSAGO: "日前",
  HOURSAGO: "時間前",
  NOW: "たった今",
  CONTACT: "お問い合わせ",
  SUBMIT: "送信",
  TRANSACTIONACT: "特定商法取引表示",
  YOUROPINION: "ご意見・要望をお書きください。",
  WRITETITLE: "タイトルを入力...",
  WRITEDESCRIPTION: "内容紹介を入力...",
  CHANGE: "変更する",
  DROPORCLICK_1: "",
  DROPORCLICK_2: "をドロップしてください。またはクリックしてください。",
  PURCHASE: "購入",
  PURCHASED: "購入済み",
  AMOUNTANDMESSAGEPUBLIC: "金額とコメントは公開されます",
  HOWTOENJOY: "Magicodeの使い方",
  HOWTOUSE: "使い方",
  REPLAY: "返信",
  PUBLISH: "公開",
  PUBLISHED: "公開しました",
  DRAFTPREVIEW: "下書きのプレビューを表示中",
  READNOW: "今すぐ読む",
  UPDATE: "更新",
  BOOKPURCHASENOTE:
    "本を購入すると中の記事を全て読むことができます。記事単体の購入もできます。",
  ADDARTICLE: "Articleを作成",
  ADDARTICLETOBOOK: "Articleを追加",
  ADDBOOK: "Bookを作成",
  ADDNEW: "作成する",
  NEEDARTICLETITLE: "記事のタイトルが必要です。",
  NEEDARTICLEBODY: "記事の本文が必要です。",
  NEEDARTICLETAG: "記事のタグは一つ以上必要です。",
  NEEDBOOKTITLE: "本のタイトルが必要です。",
  NEEDBOOKDESCRIPTION: "本の内容紹介が必要です。",
  NEEDBOOKTAG: "本のタグは一つ以上が必要です。",
  CHECKNETWORKCONNECTION: "通信環境を確認してください。",
  ARTICLEBOOK: "記事をまとまった形でシェアしましょう。",
  PAIDARTICLEPAIDBOOK:
    "有料記事の値段は本の値段と有料記事の数で自動的に決まります。",
  TITLELIMIT: "タイトルは100文字以下にする必要があります。",
  PAIDARTICLEORBOOK: "有料公開する",
  CONNECTING: "接続中です...📶",
  CONNECTIONFAILED:
    "接続に失敗しました。\nもう一回実行するか、しばらくしてから試してくださいm(_ _)m",
  ONLYAUTHER: "作者以外は編集できません、トップページに戻ります",
  SAVEDRAFT: "下書きに保存する",
  SAVING: "保存中です...",
  SAVED: "保存済み",
  SAVEDSUCCESSFULLY: "保存に成功しました!!",
  PUBLISHING: "公開中です...",
  LATESTVERSION: "最新版です",
  FILENAMELIMIT: "ファイル名は100文字以下でお願いします。",
  LEAVEPAGE: "変更を保存せずに終了しますか?",
  EDITING: "編集中...",
  SELECTLANGUAGE: "言語を選択する",
  IMPORTFILE: "ファイルのインポート",
  YOUCANIMPORT_EN: "",
  YOUCANIMPORT_JA: "を記事に追加できます。",
  YOUCANADD_EN: "",
  YOUCANADD_JA: "を追加できます",
  LOCAL: "ローカル",
  ADD: "追加する",
  ADDING: "追加中...",
  SELECTEDFILE: "選択したファイル",
  ARTICLEPUBLICLYAVAILABLE: "記事を公開しました。",
  ARTICLEPUBLICLYUNAVAILABLE: "記事は現在非公開です。",
  BOOKPUBLICLYAVAILABLE: "本と中の記事を公開しました。",
  BOOKPUBLICLYUNAVAILABLE: "本と中の記事は現在非公開です。",
  WRITEMARKDOWN: "マークダウン記法で入力...",
  IMPORTIMAGE: "画像を挿入",
  IMPORTIMAGE_EXPLANATION: "クリックかドラッグ&ドロップ",
  ADDBLANK: "白紙を追加",
  ADDMYARTICLE: "既存のArticleを追加",
  FREEBOOKARTICLE: "本が無料なので、無料公開されます。",
  CANCHANGELANGUAGE: "実行言語は本の設定で変更できます。",
  FAVORITE: "人気順",
  LATEST: "最新順",
  ALL: "すべて",
  SHOW: "表示",
  SUCCEEDED: "成功しました",
  FAILED: "失敗しました",
  DARKMODE: "ダークモード",
  INTRODUCTION: "トップページでサービス紹介欄を表示する",
  EDITORTUTRIAL:
    "実行しないテキストはマークダウンセルに、実行するコードはコードセルに書いてください。",

  SHAREYOURKNOWLEDGE: "知見をシェアする",
  REQUESTSUCCEEDED: "申請に成功しました",
  REQUESTFAILED: "申請に失敗しました",
  TOTALSALES: "売上総額",
  CURRENCY: "¥",
  RECEIVEDSALES: "受け取り済み金額",
  THISMOUNTHSALES: "今月の売り上げ",
  RECEIVABLESALES: "受取可能金額",
  WHENICANRECIEVE: "¥1,000を超えると振込申請できるようになります。",
  python: `print("${msg}")`,
  r: `print("${msg}")`,
  julia: `println("${msg}")`,
  go: `import ("fmt")
func main() {
  fmt.Println("${msg}")
}
main()`,
  javascript: `console.log("${msg}")`,
  typescript: `console.log("${msg}")`,
  cpp: `#include <iostream>

std::cout << "${msg}" << std::endl;`,
  csharp: `Console.WriteLine("${msg}")`,
  fsharp: `printfn "${msg}"`,
  haskell: `putStrLn "${msg}"`,
  ruby: `print "${msg}"`,
  java: `System.out.println("${msg}")`,
  kotlin: `println("${msg}")`,
  scala: `println("${msg}")`,
  rust: `println!("${msg}")`,
  c: `printf("${msg}");`,
};
