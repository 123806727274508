import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NextNprogress from "nextjs-progressbar";
import React from "react";
import { useAppState } from "./AppProvider";

export default function MyThemeProvider({ children }: any) {
  const getDesignTokens: any = (mode: any) => ({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // palette values for light mode
            primary: { main: "#393939" },
            secondary: { main: "#ff6a14" },
            warning: { main: "#ff8a65" },
            info: { main: "#ffffff" },
          }
        : {
            // palette values for dark mode
            primary: { main: "#ffffff" },
            secondary: { main: "#ff8a46" },
            warning: { main: "#ff8a65" },
            info: { main: "#ffffff" },
          }),
    },

    typography: {
      button: {
        textTransform: "none",
      },
    },
  });
  const [state] = useAppState();
  const isDarkMode = state.darkMode;

  const theme = React.useMemo(() => {
    const mode = isDarkMode ? "dark" : "light";
    return createTheme(getDesignTokens(mode));
  }, [isDarkMode]);
  return (
    <ThemeProvider theme={theme}>
      <NextNprogress
        startPosition={0.3}
        stopDelayMs={200}
        height={1}
        color="primary"
        showOnShallow={true}
      />

      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
