//https://panda-program.com/posts/nextjs-google-analytics
export const GA_ID = process.env.GA_ID;
export const UA_ID = process.env.UA_ID;

// IDが取得できない場合を想定する
export const existsGaId = GA_ID !== "" && UA_ID !== "";
declare global {
  interface Window {
    gtag: any;
  }
}
// PVを測定する
export const pageview = (path: any) => {
  if (!window.gtag) {
  } else {
    window.gtag("config", GA_ID, {
      page_path: path,
    });
    // window.gtag("config", UA_ID, {
    //   page_path: path,
    // });
    // window.gtag("get", UA_ID, "page_path", (path) => {
    //   console.log(path);
    // });
  }
};

// GAイベントを発火させる
export const event = ({ action, category, label, value = "" }: any) => {
  if (!window.gtag) {
    return;
  } else {
    window.gtag("event", action, {
      event_category: category,
      event_label: JSON.stringify(label),
      value,
    });
  }
};
