import { createContext, useContext, useEffect, useReducer } from "react";
import { fetchMyAccountInfo } from "@/src/api/index";

const Context = createContext<any>([]);
const { Provider } = Context;
let mql: any;

const AppProvider = (props: any) => {
  const saveToLocal = (state: any) => {
    let stateCopy = { ...state };
    delete stateCopy.mobile;
    localStorage.setItem("magicode-ad-settings", JSON.stringify(stateCopy));
  };
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      // dark mode
      case "toggleDarkMode": {
        return { ...state, darkMode: action.payload };
      }
      case "setUserInfo": {
        const newState = { ...state, userInfo: action.payload };
        saveToLocal(newState);
        return newState;
      }
      case "setRefresh": {
        const newState = { ...state, refresh: state.refresh + 1 };
        saveToLocal(newState);
        return newState;
      }
      case "setMobile":
        return { ...state, mobile: !mql.matches };
      case "initialSetup": {
        const settings = JSON.parse(
          localStorage.getItem("magicode-ads-settings")
        );
        const darkMode =
          localStorage.theme === "dark" ||
          (!("theme" in localStorage) &&
            window.matchMedia("(prefers-color-scheme: dark)").matches);
        return {
          ...state,
          mobile: !mql.matches,
          ...settings,
          darkMode,
        };
      }
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, {
    name: "Magicode-ads",
    mobile: false,
    darkMode: false,
    userInfo: false,
    refresh: 0,
  });
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      mql = window.matchMedia(`(min-width: 992px)`);
      mql.addListener(mediaQueryChanged);
      !unmounted && dispatch({ type: "initialSetup" });
      fetchMyAccountInfo()
        .then((res: any) => {
          !unmounted && dispatch({ type: "setUserInfo", payload: res.data });
        })
        .catch((error) => {
          console.log(error);
          !unmounted && dispatch({ type: "setUserInfo", payload: undefined });
        });
    }

    return () => {
      mql.removeListener(mediaQueryChanged);
      unmounted = true;
    };
  }, [state.refresh]);

  const mediaQueryChanged = () => {
    dispatch({ type: "setMobile" });
  };

  return <Provider value={[state, dispatch]}>{props.children}</Provider>;
};

export default AppProvider;
export const useAppState = () => useContext(Context);
