import { Auth } from "@aws-amplify/auth";
import axios, { Method } from "axios";

const getCognitoUser = async () => {
  const currentSession: any = await Auth.currentSession();
  return currentSession.idToken.jwtToken;
};

/** Cognitoの認証情報をつけて、引数で指定したオプションでaxiosによって通信する */
const requestWithJWT = async (
  method: Method,
  path: string,
  data = {},
  isMultiForm = false,
  host = process.env.BACKEND_API
) => {
  const jwtToken = await getCognitoUser();
  let headers = { Authorization: "JWT " + jwtToken };
  if (isMultiForm) {
    headers["content-type"] = "multipart/form-data";
  }
  return axios.request({
    method: method,
    headers: headers,
    url: host + path,
    data: data,
  });
};
export default requestWithJWT;
