import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  red,
  green,
  orange,
  purple,
  blue,
  deepPurple,
  teal,
  indigo,
  grey,
} from "@mui/material/colors";

const colors = [red, green, orange, purple, blue, deepPurple, teal, indigo];
const NewAvatar = ({ space, src, name }: any) => {
  const color = name ? colors[name.length % colors.length] : grey;
  // const color = colors[0]; //一旦エラーが出たのでかえた
  const useStyles = makeStyles((theme: any) => ({
    avatar: {
      backgroundColor: src ? color[0] : color[500],
      width: theme.spacing(space),
      height: theme.spacing(space),
    },
  }));

  const classes = useStyles();
  return src ? (
    <Avatar src={src} aria-label="author-name" className={classes.avatar} />
  ) : (
    <Avatar aria-label="author-name" className={classes.avatar}>
      <b
        style={{
          fontSize: `${Math.floor(8 * space * 0.6)}px`,
        }}
      >
        {name ? name.charAt(0) : ""}
      </b>
    </Avatar>
  );
};

export default NewAvatar;
