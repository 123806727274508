import {
  AccountCircle,
  AddCircle,
  DarkMode,
  Help,
  LightMode,
  Menu as MenuIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { Auth } from "aws-amplify";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useAppState } from "./AppProvider";
import Avatar from "./Avatar";
import { useLocale } from "./lang/UseLocale";
const drawerWidth = 240;
const PrimarySearchAppBar = ({ isDarkMode, themeHandler, children }: any) => {
  const { t } = useLocale();
  const router = useRouter();
  const [state] = useAppState();

  //以下アカウントメニュー
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "account-menu";
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{
        zIndex: "9999",
        top: "45px",
      }}
    >
      <MenuItem
        onClick={() => {
          router.push(`/account`);
          handleMenuClose();
        }}
        className="border-solid border-b border-blue-50 dark:border-gray-700"
      >
        <b>{t.ACCOUNT}</b>
      </MenuItem>

      <MenuItem
        onClick={() => {
          router.push("/account?mode=edit");
          handleMenuClose();
        }}
        className="border-solid border-b border-blue-50 dark:border-gray-700"
      >
        <b>{t.SETTINGS}</b>
      </MenuItem>

      <MenuItem
        onClick={() => {
          Auth.signOut({
            global: true,
          });
        }}
      >
        <b>{t.LOGOUT}</b>
      </MenuItem>
    </Menu>
  );
  const [sideBar, setSideBar] = useState<boolean>(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSideBar(open);
    };
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div style={{ height: "60px" }} />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              router.push("/");
            }}
          >
            <ListItemText primary={"ホーム"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              router.push("/payments/settings");
            }}
          >
            <ListItemText primary={"支払い方法"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              router.push("/payments/deposit");
            }}
          >
            <ListItemText primary={"予算の追加"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              router.push("/payments");
            }}
          >
            <ListItemText primary={"決済履歴"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100%)` },
            ml: { sm: `${drawerWidth}px` },
            zIndex: (theme) => theme.zIndex.drawer + 1,
            boxShadow: "none",
            maxWidth: "100vw",
          }}
          className="bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-solid border-b border-blue-50 dark:border-gray-700"
        >
          <Toolbar
            style={{ height: "60px !important" }}
            className="bg-white dark:bg-gray-800"
          >
            <Grid container className="">
              <Grid item md={12} className="p-0 flex items-center">
                <IconButton
                  style={{ padding: "4px" }}
                  onClick={toggleDrawer(true)}
                  className="md:hidden"
                >
                  <MenuIcon style={{ width: "36px", height: "36px" }} />
                </IconButton>

                <span className="pl-2 pr-1 hidden md:block">
                  <Link scroll={false} href="/">
                    <a className="logo-color">
                      <div
                        style={{
                          height: "35px",
                          lineHeight: "35px",
                        }}
                      >
                        <Image
                          src="/ads.svg"
                          alt="Magicode logo"
                          width={35}
                          height={35}
                          priority
                          className="align-middle"
                        />
                      </div>
                    </a>
                  </Link>
                </span>
                <div className="hidden md:block">
                  <Link scroll={false} href="/">
                    <a className="block text-2xl font-bold">Magicode Ads</a>
                  </Link>
                </div>

                <div className="grow" />
                <span className="pr-2">
                  <IconButton
                    style={{ padding: "4px" }}
                    onClick={() => {
                      router.push("/help");
                    }}
                  >
                    <Help
                      style={{
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </IconButton>
                </span>
                <div>
                  <IconButton
                    style={{ padding: "8px" }}
                    color="inherit"
                    onClick={() => {
                      themeHandler();
                    }}
                  >
                    {isDarkMode ? <LightMode /> : <DarkMode />}
                  </IconButton>
                </div>
                {state.userInfo && (
                  <>
                    <span className="px-2 md:px-3">
                      <span
                        id={menuId}
                        onClick={handleProfileMenuOpen}
                        style={{ cursor: "pointer" }}
                      >
                        {!state.userInfo.icon ? (
                          <>
                            <AccountCircle
                              style={{ width: "40px", height: "40px" }}
                            />
                          </>
                        ) : (
                          <Avatar
                            space={5}
                            src={state.userInfo?.icon || ""}
                            name={state.userInfo?.username || "user"}
                          />
                        )}
                      </span>
                    </span>

                    <IconButton
                      style={{ padding: "4px" }}
                      className="text-primary dark:text-primary-light"
                      onClick={() => {
                        router.push("/ad/add");
                      }}
                    >
                      <AddCircle
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {renderMenu}
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            anchor={"left"}
            open={sideBar}
            onClose={toggleDrawer(false)}
            variant="temporary"
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {list()}
          </Drawer>

          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {list()}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default PrimarySearchAppBar;
