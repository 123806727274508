import AppProvider from "@/components/AppProvider";
import MyThemeProvider from "@/components/MyThemeProvider";
import Page from "@/components/Page";
import { fetchMyAccountInfo } from "@/src/api";
import awsConfig from "@/src/config/awsConfig";
import * as gtag from "@/src/lib/gtag";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Amplify, { Auth } from "aws-amplify";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import "react-image-crop/src/ReactCrop.scss";
import "tailwindcss/tailwind.css";
import "../styles/scss/app.scss";

Amplify.configure(awsConfig);
Auth.configure(awsConfig); // stagingやproductionで動かす際にはこの1行が必要です。

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    const handleRouteChange = (path: any) => {
      gtag.pageview(path);
    };
    fetchMyAccountInfo()
      .then((res: any) => {
        if (res.data.username.trim() == "") {
          router.asPath != "/account?mode=edit" &&
            router.push("/account?mode=edit");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />

        <meta charSet="utf-8" />

        <base href={`${process.env.baseUrl}`} target="_top" />

        <link rel="icon" href="/ads.svg" type="image/svg+xml" />

        <link rel="mask-icon" href="/ads.svg" />

        <link rel="apple-touch-icon" href="/ads.svg" type="image/svg+xml" />

        <link rel="shortcut icon" href="/favicon.ico" />

        <meta name="theme-color" content="#ff6a14" />

        <meta name="msapplication-TileColor" content="#ffffff" />
      </Head>
      <Authenticator>
        <AppProvider>
          <MyThemeProvider>
            <Page>
              <Component {...pageProps} />
            </Page>
          </MyThemeProvider>
        </AppProvider>
      </Authenticator>
    </>
  );
}
