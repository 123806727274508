import React, { useEffect, useState } from "react";
import Router from "next/router";
import { useAppState } from "./AppProvider";
import { useRouter } from "next/router";
import Header from "./Header";
import Footer from "./Footer";

import * as gtag from "@/src/lib/gtag";

const Page = (props: any) => {
  const [state, dispatch] = useAppState();
  Router.events.on("routeChangeComplete", () => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
  });

  const router = useRouter();
  const pathname = router.pathname;
  const noPaddPage = ["/404", "/500"];

  const noFooter = [
    "/articles/[slug]/edit",
    "/books/[slug]/edit",
    "/onboarding",
  ];
  const noOverflowX = ["/onboarding"];
  const noScrollBarPage = ["/"];
  const isNoPadd = noPaddPage.includes(pathname) && state.mobile;
  const isNoFooter = noFooter.includes(pathname);
  const isNoScrollBar = noScrollBarPage.includes(pathname);
  const isNoOverFlowX = noOverflowX.includes(pathname);

  //最上位のhtmlタグでdark classをコントロールする
  function applyTheme() {
    var storageKey = "theme";
    var classNameDark = "dark";
    var classNameLight = "light";

    function setClassOnDocumentBody() {
      var html = document.getElementsByTagName("html")[0];
      html.classList.add(classNameDark);
    }
    function deleteClassOnDocumentBody() {
      var html = document.getElementsByTagName("html")[0];
      html.classList.remove(classNameDark);
    }

    var preferDarkQuery = "(prefers-color-scheme: dark)";
    var mql = window.matchMedia(preferDarkQuery);
    var supportsColorSchemeQuery = mql.media === preferDarkQuery;
    var localStorageTheme = null;
    try {
      localStorageTheme = localStorage.getItem(storageKey);
    } catch (err) {}
    var localStorageExists = localStorageTheme !== null;

    if (localStorageExists) {
      if (localStorageTheme === classNameDark) {
        setClassOnDocumentBody();
      } else {
        deleteClassOnDocumentBody();
      }
    } else if (supportsColorSchemeQuery) {
      setClassOnDocumentBody();
      localStorage.setItem(storageKey, classNameDark);
    } else {
      deleteClassOnDocumentBody();
      localStorage.setItem(storageKey, classNameLight);
    }
  }
  function applyAboutShow() {
    var storageKey = "toppageAbout";
    var classNameShow = "about-show";

    function setClassOnDocumentBody() {
      var html = document.getElementsByTagName("html")[0];
      html.classList.add(classNameShow);
    }
    function deleteClassOnDocumentBody() {
      var html = document.getElementsByTagName("html")[0];
      html.classList.remove(classNameShow);
    }

    var localStorageTheme = null;
    try {
      localStorageTheme = window.localStorage.getItem(storageKey);
    } catch (err) {}
    var localStorageExists = localStorageTheme !== null;

    if (localStorageExists) {
      if (localStorageTheme === classNameShow) {
        setClassOnDocumentBody();
      } else {
        deleteClassOnDocumentBody();
      }
    } else {
      setClassOnDocumentBody();
      window.localStorage.setItem(storageKey, classNameShow);
    }
  }
  const [switched, setSwitched] = useState(0);
  useEffect(() => {
    applyTheme();
    if (switched > 1) {
      gtag.event({
        action: state.darkMode ? "switch_to_darkmode" : "switch_to_lightmode",
        category: "ui",
        label: "customize_ui",
      });
    } else {
      setSwitched(switched + 1);
    }
  }, [state.darkMode]);
  useEffect(() => {
    applyAboutShow();
  }, [state.toppageAbout]);
  function themeHandler() {
    if (state.darkMode) {
      localStorage.setItem("theme", "light");
      dispatch({ type: "toggleDarkMode", payload: false });
    } else {
      localStorage.setItem("theme", "dark");
      dispatch({ type: "toggleDarkMode", payload: true });
    }
  }
  return (
    <>
      <div
        className={
          "page bg-white dark:bg-gray-800 text-gray-900 dark:text-white" +
          (isNoScrollBar ? " no-scroll-bar" : "")
        }
      >
        <Header isDarkMode={state.darkMode} themeHandler={themeHandler}>
          <div
            style={{
              paddingTop: isNoPadd ? "0px" : "60px",
              minHeight: "100vh",
            }}
            className={isNoOverFlowX ? "overflow-x-hidden" : ""}
          >
            {props.children}
          </div>

          {!isNoFooter && <Footer />}
        </Header>
      </div>
    </>
  );
};

export default Page;
